import type { FC } from "react"
import { useI18n } from "next-localization"
import { UiMobileModal } from "@app/ui/ui-mobile-modal"
import { UiFormField } from "@app/ui/ui-form-field"
import { Button } from "@app/ui/button"

import styles from "./resctricted-download-guide.module.scss"

type Props = {
  visible: boolean
  clipboardValue?: string
  onCopyButtonClick: () => void
  onClose: () => void
}

const ResctrictedDownloadGuide: FC<Props> = ({ visible, clipboardValue, onCopyButtonClick, onClose }) => {
  const { t } = useI18n()

  return (
    <>
      <UiMobileModal open={visible} onClose={onClose}>
        <UiMobileModal.Header className={styles["title"]}>
          <div dangerouslySetInnerHTML={{ __html: t("restrictedDownloadGuide.title") }}></div>
        </UiMobileModal.Header>

        <UiMobileModal.Body>
          <UiFormField.Input name="url" readOnly defaultValue={clipboardValue} />
        </UiMobileModal.Body>

        <UiMobileModal.Footer>
          <Button onClick={onCopyButtonClick} className={styles["button"]}>
            {t("restrictedDownloadGuide.button")}
          </Button>
        </UiMobileModal.Footer>
      </UiMobileModal>
    </>
  )
}

export { ResctrictedDownloadGuide }
