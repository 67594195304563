import type { FC, InputHTMLAttributes, RefCallback } from "react"
import clsx from "clsx"

import styles from "./ui-form-field-input.module.scss"

type Props = {
  className?: string
  isError?: boolean
  register?: RefCallback<HTMLInputElement>
} & InputHTMLAttributes<HTMLInputElement>

const UiFormFieldInput: FC<Props> = ({ className, isError, register, ...inputProps }) => {
  return (
    <input ref={register} className={clsx(className, styles["input"], isError && styles["error"])} {...inputProps} />
  )
}

export { UiFormFieldInput }
