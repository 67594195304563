import clsx from "clsx"
import type { FC, PropsWithChildren } from "react"

import styles from "./ui-mobile-modal-footer.module.scss"

type Props = PropsWithChildren<{
  className?: string
}>

const UiModalFooter: FC<Props> = ({ className, children }) => {
  return <div className={clsx(className, styles["modal-footer"])}>{children}</div>
}

export { UiModalFooter }
