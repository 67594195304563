import type { FC, PropsWithChildren } from "react"
import { createPortal } from "react-dom"
import { useClickOutside, useIsNodeMounted, useScrollLock } from "@app/utils/hooks"
import { UiMobileModalHeader } from "./ui-mobile-modal-header"
import { UiMobileModalBody } from "./ui-mobile-modal-body"
import { UiModalFooter } from "./ui-mobile-modal-footer"
import { Cross } from "../icons"

import styles from "./ui-mobile-modal.module.scss"

type Props = PropsWithChildren<{
  className?: string
  open: boolean
  onClose: () => void
}>

interface UiModal extends FC<PropsWithChildren<Props>> {
  Header: typeof UiMobileModalHeader
  Body: typeof UiMobileModalBody
  Footer: typeof UiModalFooter
}

const UiMobileModal: UiModal = ({ open = false, onClose, children }) => {
  const isNodeMounted = useIsNodeMounted()
  useScrollLock(open)

  const handleOutsideClick = () => {
    onClose()
  }

  const ref = useClickOutside(handleOutsideClick)

  if (!isNodeMounted || !open) return null

  const modal = (
    <div className={styles["overlay"]}>
      <div ref={ref} className={styles["content"]}>
        <button onClick={onClose} className={styles["close-button"]}>
          <Cross />
        </button>

        {children}
      </div>
    </div>
  )

  return createPortal(modal, document.getElementById("modal") as HTMLDivElement)
}

UiMobileModal.Header = UiMobileModalHeader
UiMobileModal.Body = UiMobileModalBody
UiMobileModal.Footer = UiModalFooter

export { UiMobileModal }
