import type { FC, PropsWithChildren } from "react"
import clsx from "clsx"

import styles from "./ui-mobile-modal-header.module.scss"

type Props = {
  className?: string
}

const UiMobileModalHeader: FC<PropsWithChildren<Props>> = ({ className, children }) => {
  return <div className={clsx(className, styles["modal-header"])}>{children}</div>
}

export { UiMobileModalHeader }
