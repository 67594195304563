import type { FC, PropsWithChildren } from "react"
import clsx from "clsx"

import styles from "./ui-form-field-error.module.scss"

type Props = PropsWithChildren<{
  className?: string
}>

const UiFormFieldError: FC<Props> = ({ className, children }) => {
  if (!children) return null

  return <div className={clsx(className, styles["error"])}>{children}</div>
}

export { UiFormFieldError }
