import type { FC, PropsWithChildren } from "react"
import clsx from "clsx"

import styles from "./ui-mobile-modal-body.module.scss"

type Props = PropsWithChildren<{
  className?: string
}>

const UiMobileModalBody: FC<Props> = ({ className, children }) => {
  return <div className={clsx(className, styles["modal-body"])}>{children}</div>
}

export { UiMobileModalBody }
