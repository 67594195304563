import clsx from "clsx"
import type { FC, PropsWithChildren } from "react"
import { UiFormFieldInput } from "./components/ui-form-field-input"
import { UiFormFieldLabel } from "./components/ui-form-field-label"
import { UiFormFieldError } from "./components/ui-form-field-error"

type Props = PropsWithChildren<{
  className?: string
}>

interface UiFormField extends FC<PropsWithChildren<Props>> {
  Input: typeof UiFormFieldInput
  Label: typeof UiFormFieldLabel
  Error: typeof UiFormFieldError
}

const UiFormField: UiFormField = ({ className, children }) => {
  return <div className={clsx(className)}>{children}</div>
}

UiFormField.Input = UiFormFieldInput
UiFormField.Label = UiFormFieldLabel
UiFormField.Error = UiFormFieldError

export { UiFormField }
