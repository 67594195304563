import type { FC, PropsWithChildren } from "react"
import clsx from "clsx"

import styles from "./ui-form-field-label.module.scss"

type Props = PropsWithChildren<{
  className?: string
}>

const UiFormFieldLabel: FC<Props> = ({ className, children }) => {
  return (
    <div className={clsx(className, styles["label"])}>
      <label>{children}</label>
    </div>
  )
}

export { UiFormFieldLabel }
